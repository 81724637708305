<template>
    <section class="flex flex-col justify-center items-center py-12 md:py-16 lg:py-20" id="socials">
        <div class="py-8 px-4">
            <img v-motion-pop-visible src="../assets/socials.png" alt="socials">
        </div>
        <div class="flex flex-col lg:flex-row justify-center items-stretch gap-6">
            <div v-motion-slide-visible-bottom class="flex flex-col justify-center items-center bg-[#13131B] border border-[#20202E] py-6 px-10 mx-4 md:mx-0">
                <img src="../assets/yt-img.png" class="w-[120px]" alt="yt-img">
                <div class="flex items-center py-2">
                    <img src="../assets/arrow-right.png" class="hidden md:block" alt="arrow-right">
                    <p class="text-sm text-white font-bold uppercase">&nbsp; Youtube &nbsp;</p>
                    <img src="../assets/arrow-left.png" class="hidden md:block" alt="arrow-right">
                </div>
                <div class="flex flex-col gap-4">
                    <div class="flex flex-col justify-center items-center bg-[#0D0D12] rounded px-16 py-6">
                        <p class="uppercase text-sm text-white font-semibold text-center">enjoy my daily videos</p>
                    </div>
                    <a href="https://www.youtube.com/channel/UCco8g2xZkxd3HPsvoE1ebXg" target="_blank">
                        <div class="text-center transition ease-in-out delay-100 hover:scale-105 duration-200 uppercase text-sm font-black text-white px-16 py-4 bg-gradient-to-t from-[#82112C] to-[#EEAF0E] rounded">
                            Subscribe
                        </div>
                    </a>
                </div>
            </div>
            <div v-motion-slide-visible-bottom class="flex flex-col justify-center items-center bg-[#13131B] border border-[#20202E] py-6 px-10 mx-4 md:mx-0">
                <img src="../assets/x-img.png" class="mt-1 w-[100px]" alt="x-img">
                <div class="flex items-center py-2">
                    <img src="../assets/arrow-right.png" class="hidden md:block" alt="arrow-right">
                    <p class="text-sm text-white font-bold uppercase">&nbsp; x/twitter &nbsp;</p>
                    <img src="../assets/arrow-left.png" class="hidden md:block" alt="arrow-right">
                </div>
                <div class="flex flex-col gap-4">
                    <div class="flex flex-col justify-center items-center bg-[#0D0D12] rounded px-6 py-6">
                        <p class="uppercase text-sm text-white font-semibold text-center">Follow for frequent giveaways</p>
                    </div>
                    <a href="https://x.com/Old_Metro" target="_blank">
                        <div class="text-center transition ease-in-out delay-100 hover:scale-105 duration-200 uppercase text-sm font-black text-white px-16 py-4 bg-gradient-to-t from-[#82112C] to-[#EEAF0E] rounded">
                            Follow
                        </div>
                    </a>
                </div>
            </div>
            <div v-motion-slide-visible-bottom class="flex flex-col justify-center items-center bg-[#13131B] border border-[#20202E] py-6 px-10 mx-4 md:mx-0">
                <img src="../assets/discord-img.png" class="w-[110px]" alt="discord-img">
                <div class="flex items-center py-3">
                    <img src="../assets/arrow-right.png" class="hidden md:block" alt="arrow-right">
                    <p class="text-sm text-white font-bold uppercase">&nbsp; discord &nbsp;</p>
                    <img src="../assets/arrow-left.png" class="hidden md:block" alt="arrow-right">
                </div>
                <div class="flex flex-col gap-4">
                    <div class="flex flex-col justify-center items-center bg-[#0D0D12] rounded px-16 py-6">
                        <p class="uppercase text-sm text-white font-semibold text-center">Join the community</p>
                    </div>
                    <a href="https://discord.com/invite/gRWKSGJzrw" target="_blank">
                        <div class="text-center transition ease-in-out delay-100 hover:scale-105 duration-200 uppercase text-sm font-black text-white px-16 py-4 bg-gradient-to-t from-[#82112C] to-[#EEAF0E] rounded">
                            Join
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>
