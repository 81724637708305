<template>
    <!-- Navbar start -->
    <nav :class="{'bg-black shadow-md transition-opacity bg-opacity-80': isScrolled || showMenu, 'bg-transparent': !isScrolled && !showMenu }" class="w-full fixed p-2 px-5 md:px-5 lg:px-8 flex justify-between z-40 transition-all h-20">
        <router-link @click="showMenu = false; this.isScrolled = false;" to="/home">
            <div class="h-full flex flex-row justify-between items-center">
                <img v-motion-pop-visible src="../assets/old-metro-title.png" class="w-[180px]" alt="logo">
            </div>
        </router-link>

        <button role="menubar" class="lg:hidden" @click="toggleMenu()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="w-9 h-8">
                <path fill-rule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
            </svg>
        </button>

        <div :class="{
            'bg-opacity-80 bg-black lg:bg-transparent': isScrolled || showMenu,
            'bg-opacity-80': !isScrolled && !showMenu,
            'hidden': !showMenu && isMobile(),
            'flex': showMenu || !isMobile()
            }"
            class="absolute left-0 right-0 mt-[72.1px] md:mt-[71px] lg:mt-2 shadow lg:flex flex-col gap-4 justify-end items-start lg:item-center p-2 lg:flex-row lg:static lg:shadow-none lg:translate-y-0 transition-all"
            role="menu" :aria-expanded="showMenu.toString()"
        >
            <div v-motion-pop-visible class="flex flex-col gap-[20px] lg:mb-0 lg:flex-row justify-evenly uppercase whitespace-nowrap text-lg text-white" style="font-family: Poppins, sans-serif">
                <router-link @click="showMenu = false; this.isScrolled = false;" to="/home" class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl">Home</router-link>
                <router-link @click="showMenu = false; this.isScrolled = false;" to="/leaderboard" class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl">Leaderboard</router-link>
                <router-link @click="showMenu = false; this.isScrolled = false;" to="/socials" class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl">Socials</router-link>
                <!-- <router-link @click="showMenu = false; this.isScrolled = false;" to="/challenges" class="px-4 py-1.5 routerBtn text-base font-bold transition-colors rounded-xl">Challenges</router-link> -->
                <!-- <a href="#challenges" @click="showMenu = false" role="menuitem" class="px-4 py-1.5 routerBtn text-base font-semibold transition-colors">
                    Challenges
                </a> -->
            </div>
        </div>
    </nav>
    <!-- Navbar end -->
</template>

<script>

export default {
    data() {
        return {
            isScrolled: false,
            isLogout: false,
            showMenu: false,
        };
    },
    methods: {
        navigateAndCloseMenu(route) {
            this.showMenu = false;
            this.$router.push(route);
        },
        handleScroll() {
            this.isScrolled = window.scrollY > 0;

			if (this.showMenu && window.innerWidth < 768) {
                this.isScrolled = true;
            }
        },
		toggleMenu() {
            this.showMenu = !this.showMenu;
            if (this.showMenu && window.innerWidth < 768) {
                this.isScrolled = true;
            } else if (!this.showMenu && window.scrollY === 0) {
                this.isScrolled = false;
            }
        },
        isMobile() {
            return window.innerWidth < 1024; // Adjust based on your 'lg' breakpoint in Tailwind CSS config
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>

</style>