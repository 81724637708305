<template>
  <section class="h-auto" id="challenges">
      <div class="flex flex-col justify-center items-center py-8 md:py-0 lg:py-32 lg:px-20">
         <div class="flex flex-wrap justify-center py-10 px-8 gap-4 md:gap-6">
            <div v-motion-pop-visible class="flex flex-col md:flex-row items-center md:items-end bg-[#0D0D12] border border-[#20202E] p-5 md:p-6 md:min-w-[550px]">
               <div class="max-w-[180px]">
                  <img src="../assets/challenges/thunder.png" alt="thunder">
               </div>
               <div class="flex flex-col md:ml-6 gap-3">
                  <p class="text-white text-sm font-bold text-center mt-4 md:mt-0">12 Bolts of Thunder</p>
                  <div class="flex flex-row justify-center gap-6">
                      <div class="flex flex-col items-center gap-1">
                          <img src="../assets/target.png" class="w-[15px]" alt="target">
                          <p class="text-white text-xs font-bold">x500</p>
                      </div>
                      <div class="flex flex-col items-center gap-1">
                          <img src="../assets/chip.png" class="w-[15px]" alt="chip">
                          <p class="text-white text-xs font-bold">min. $1</p>
                      </div>
                  </div>
                  <button class="bg-gradient-to-t from-[#82112C] to-[#EEAF0E] rounded px-32 py-2 text-white text-sm font-bold md:mt-6">
                      Join
                  </button>
               </div>
            </div>
            <div v-motion-pop-visible class="flex flex-col md:flex-row items-center md:items-end bg-[#0D0D12] border border-[#20202E] p-5 md:p-6 md:min-w-[550px]">
               <div class="max-w-[180px]">
                  <img src="../assets/challenges/thunder.png" alt="thunder">
               </div>
               <div class="flex flex-col md:ml-6 gap-3">
                  <p class="text-white text-sm font-bold text-center mt-4 md:mt-0">12 Bolts of Thunder</p>
                  <div class="flex flex-row justify-center gap-6">
                      <div class="flex flex-col items-center gap-1">
                          <img src="../assets/target.png" class="w-[15px]" alt="target">
                          <p class="text-white text-xs font-bold">x500</p>
                      </div>
                      <div class="flex flex-col items-center gap-1">
                          <img src="../assets/chip.png" class="w-[15px]" alt="chip">
                          <p class="text-white text-xs font-bold">min. $1</p>
                      </div>
                  </div>
                  <button class="bg-gradient-to-t from-[#82112C] to-[#EEAF0E] rounded px-32 py-2 text-white text-sm font-bold md:mt-6">
                      Join
                  </button>
               </div>
            </div>
            <div v-motion-pop-visible class="flex flex-col md:flex-row items-center md:items-end bg-[#0D0D12] border border-[#20202E] p-5 md:p-6 md:min-w-[550px]">
               <div class="max-w-[180px]">
                  <img src="../assets/challenges/thunder.png" alt="thunder">
               </div>
               <div class="flex flex-col md:ml-6 gap-3">
                  <p class="text-white text-sm font-bold text-center mt-4 md:mt-0">12 Bolts of Thunder</p>
                  <div class="flex flex-row justify-center gap-6">
                      <div class="flex flex-col items-center gap-1">
                          <img src="../assets/target.png" class="w-[15px]" alt="target">
                          <p class="text-white text-xs font-bold">x500</p>
                      </div>
                      <div class="flex flex-col items-center gap-1">
                          <img src="../assets/chip.png" class="w-[15px]" alt="chip">
                          <p class="text-white text-xs font-bold">min. $1</p>
                      </div>
                  </div>
                  <button class="bg-gradient-to-t from-[#82112C] to-[#EEAF0E] rounded px-32 py-2 text-white text-sm font-bold md:mt-6">
                      Join
                  </button>
               </div>
            </div>
            <div v-motion-pop-visible class="flex flex-col md:flex-row items-center md:items-end bg-[#0D0D12] border border-[#20202E] p-5 md:p-6 md:min-w-[550px]">
               <div class="max-w-[180px]">
                  <img src="../assets/challenges/thunder.png" alt="thunder">
               </div>
               <div class="flex flex-col md:ml-6 gap-3">
                  <p class="text-white text-sm font-bold text-center mt-4 md:mt-0">12 Bolts of Thunder</p>
                  <div class="flex flex-row justify-center gap-6">
                      <div class="flex flex-col items-center gap-1">
                          <img src="../assets/target.png" class="w-[15px]" alt="target">
                          <p class="text-white text-xs font-bold">x500</p>
                      </div>
                      <div class="flex flex-col items-center gap-1">
                          <img src="../assets/chip.png" class="w-[15px]" alt="chip">
                          <p class="text-white text-xs font-bold">min. $1</p>
                      </div>
                  </div>
                  <button class="bg-gradient-to-t from-[#82112C] to-[#EEAF0E] rounded px-32 py-2 text-white text-sm font-bold md:mt-6">
                      Join
                  </button>
               </div>
            </div>
            <div v-motion-pop-visible class="flex flex-col md:flex-row items-center md:items-end bg-[#0D0D12] border border-[#20202E] p-5 md:p-6 md:min-w-[550px]">
               <div class="max-w-[180px]">
                  <img src="../assets/challenges/thunder.png" alt="thunder">
               </div>
               <div class="flex flex-col md:ml-6 gap-3">
                  <p class="text-white text-sm font-bold text-center mt-4 md:mt-0">12 Bolts of Thunder</p>
                  <div class="flex flex-row justify-center gap-6">
                      <div class="flex flex-col items-center gap-1">
                          <img src="../assets/target.png" class="w-[15px]" alt="target">
                          <p class="text-white text-xs font-bold">x500</p>
                      </div>
                      <div class="flex flex-col items-center gap-1">
                          <img src="../assets/chip.png" class="w-[15px]" alt="chip">
                          <p class="text-white text-xs font-bold">min. $1</p>
                      </div>
                  </div>
                  <button class="bg-gradient-to-t from-[#82112C] to-[#EEAF0E] rounded px-32 py-2 text-white text-sm font-bold md:mt-6">
                      Join
                  </button>
               </div>
            </div>
         </div>

         <div v-motion-pop-visible class="flex flex-col justify-center items-center bg-[#13131B] border border-[#20202E] mx-6 lg:mt-14 p-10 gap-6 max-w-[1000px]">
            <h1 class="text-2xl text-white uppercase font-black text-center">How it works</h1>
            <p class="text-base text-white text-center font-semibold">To participate and claim these challenges, use the referral code "OLDMETRO" on Stake. The first player to achieve the specified multiplier will receive the prize credited to their on-site balance.</p>
         </div>
         <div v-motion-pop-visible class="flex flex-col justify-center items-center bg-[#13131B] border border-[#20202E] mx-6 mt-6 md:mt-8 lg:mt-14 p-10 gap-6 max-w-[1000px]">
            <h1 class="text-2xl text-white uppercase font-black text-center">Claiming Rewards</h1>
            <p class="text-base text-white text-center font-semibold">After completing the challenge, submit your bet ID along with the date and time of your win in the official OLDMETRO Discord server. For any questions or concerns, please contact any listed staff member.</p>
         </div>
      </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      challenges: [],
    };
  },
};
</script>
<style scoped>
.challenges-bg {
  background-image: url('../assets/challenges-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
