<template>
  <!-- Footer start -->
  <div class="rocket-bg h-full w-full">
    <div class="flex flex-col justify-center items-center">
        <div class=" py-4 px-4 md:px-10 md:py-10 lg:py-6 2xl:py-16">
          <img src="../assets/my-code.png" class="lg:w-[600px]" alt="my-code">
        </div>
        <div class="flex flex-col justify-center items-center bg-[#13131B] px-6 py-8 md:px-24 md:py-10 md:mb-10 lg:px-32 lg:py-10 lg:mb-16 2xl:mb-20 gap-5">
          <img src="../assets/thank-you.png" class="lg:w-[450px]" alt="thank-you">
          <p class="text-[#9999C9] text-xs font-medium">USE CODE</p>
          <img src="../assets/old-metro-code.png" class="transition ease-in-out delay-100 hover:scale-105 duration-300 z-20 cursor-pointer" alt="old-metro-code.png">
        </div>
        <img src="../assets/planets.png" class="hidden lg:block absolute lg:w-[1000px] lg:mr-16 z-10" alt="planets">
    </div>
  </div>
  <div class="bg-gradient-to-t from-[#BEC7CC] to-[#DDE3E7]">
    <div class="flex items-center">
      <div class="flex flex-col md:flex-row justify-between items-center w-full mb-6 md:mb-0 md:px-8 lg:px-80 2xl:px-[500px]">
          <div>
              <img src="../assets/copyright.png" class="w-[200px] py-2" alt="copyright">
          </div>
          <div class="flex flex-row gap-4">
            <a href="https://discord.com/invite/gRWKSGJzrw" target="_blank">
              <img src="../assets/discord.png" alt="discord">
            </a>
            <a href="https://x.com/Old_Metro" target="_blank">
              <img src="../assets/twitter.png" alt="twitter">
            </a>  
            <a href="https://www.youtube.com/channel/UCco8g2xZkxd3HPsvoE1ebXg" target="_blank">
              <img src="../assets/youtube.png" alt="youtube">
            </a>
          </div>
      </div>
    </div>
    <div class="hidden md:flex justify-center md:px-8 lg:px-80 2xl:px-[500px] pb-4">
        <img src="../assets/18+.png" class="w-full" alt="18+">
    </div>
  </div>
  
    
  <!-- Footer end -->
</template>

<script>
export default {

};
</script>

<style scoped>
.rocket-bg {
  background-image: url('../assets/rocket-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center
}
</style>
