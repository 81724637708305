<template>
    <section class="h-auto challenges-bg">
        <div class="flex flex-col justify-center items-center px-6 md:px-12 pt-32 md:pt-44 lg:pt-44 2xl:pt-52">
            <img v-motion-pop-visible src="../assets/challenges-header.png" class="lg:w-[900px] 2xl:w-[1100px]" alt="socials">
            <!-- <div v-motion-pop-visible class="pt-14 text-white text-xl font-black text-center">
                Under Construction. Coming Soon...
            </div> -->
        </div>
        <ChallengesSection/>
    </section>
</template>

<script>
import ChallengesSection from '@/sections/ChallengesSection.vue';


export default {
    components: {
        ChallengesSection
    },
}
</script>

<style scoped>
.challenges-bg {
  background-image: url('../assets/challenges-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>