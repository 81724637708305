<template>
  <!-- Main section start -->
  <section class="h-screen main-bg" id="main">
      <div v-motion-pop-visible class="absolute bottom-0 left-0 mb-28 ml-4 md:mb-40 md:ml-6 lg:mb-24 lg:ml-14 2xl:mb-36 2xl:ml-28">
         <img src="../assets/Astro1.png" class="animate-bounce1 w-[150px] md:w-[350px] lg:w-[350px] 2xl:w-full" alt="Astro1">
      </div>
      <div v-motion-pop-visible class="absolute bottom-0 right-0 mb-36 mr-4 md:mb-52 md:mr-6 lg:mb-24 lg:mr-14 2xl:mb-36 2xl:mr-28">
         <img src="../assets/Astro2.png" class="animate-bounce2 w-[150px] md:w-[350px] lg:w-[350px] 2xl:w-full" alt="Astro2">
      </div>
      <div class="hidden lg:block absolute inset-x-0 bottom-0 top-1/2 left-1/2 -ml-11 lg:mt-56 2xl:mt-80">
         <img src="../assets/rocket.png" class="w-[40px] md:w-[80px]" alt="rocket">
      </div>
      <div class="flex flex-col pt-20 md:pt-40 p-8 lg:pt-24 2xl:pt-44 lg:px-16 2xl:px-56 z-30">
          <div class="flex justify-center mt-16">
             <img v-motion-pop-visible src="../assets/title-text.png" class="lg:w-[800px] 2xl:w-[900px]" alt="title-text">
          </div>
          <div v-motion-pop-visible class="flex flex-wrap justify-center items-stretch gap-4 mt-5 md:mt-6 lg:mt-6 z-30">
            <a href="#bonus-offer">
              <button class="text-white font-bold border border-white py-2 px-14 rounded-lg shadow-2xl hover:bg-gradient-to-r hover:from-[#83132D] hover:to-[#ED923F] hover:border-none">Bonuses</button>
            </a>
            <a href="#socials">
              <button class="text-white font-bold border border-white py-2 px-14 rounded-lg shadow-2xl hover:bg-gradient-to-r hover:from-[#83132D] hover:to-[#ED923F] hover:border-none">Socials</button>
            </a>
          </div>
      </div>
  </section>
  <section class="h-auto home-bg">
    <BonusOfferSection/>
    <SocialSection/>
  </section>
  
</template>

<script>
import SocialSection from '../sections/SocialSection.vue';
import BonusOfferSection from '../sections/BonusOfferSection.vue';
import { defineComponent } from 'vue';


export default defineComponent({
  components: {
     BonusOfferSection,
     SocialSection
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
  },
});
</script>

<style scope>
body {
  background-color: black;
}
.main-bg {
  background-image: url('../assets/hero-bg2.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.home-bg {
  background-image: url('../assets/home-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
