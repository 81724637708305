<template>
    <section class="h-screen error-bg">
        <div class="pt-40 px-6 flex flex-col justify-center items-center">
            <!-- <img loading="lazy" src="../assets/oeg-800.png" class="w-[400px]" alt="oeg"> -->
            <div>
                <img src="../assets/page-not-found.png" alt="page-not-found">
            </div>
            <div class="text-white text-center text-lg mt-8 mx-auto">
                The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
            </div>
            <div class="mt-10">
                <router-link to="/" class="px-4 py-3 text-white font-semibold rounded-md uppercase bg-gradient-to-r from-[#83132D] to-[#ED923F]">
                    Back to Home
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
       
    }
</script>

<style scoped>
.error-bg {
  background-image: url('../assets/error-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>