<template>
    <section class="flex flex-col justify-center items-center pt-20 md:pt-16 lg:pt-52 lg:pb-0 2xl:px-80">
        <div class="py-8 px-4">
            <img v-motion-pop-visible src="../assets/recent-videos.png" alt="recent-videos">
        </div>
        <div class="flex flex-wrap justify-center items-stretch gap-4">
            <a href="https://www.youtube.com/watch?v=qG7Et9VLwUg" target="_blank">
                <div v-motion-pop-visible class="flex flex-col justify-start items-center bg-[#13131B] p-4 w-[300px] gap-4">
                    <img src="../assets/thumbnails/thumbnail1.webp" class="rounded-xl w-[280px]" alt="thumbnail1">
                    <p class="text-white text-sm text-left w-[260px] truncate">I took insurance... AND stood 9... oh my.</p>
                    <p class="text-[#AAC2E6] uppercase text-xs">Click to view</p>
                </div>
            </a>
            <a href="https://www.youtube.com/watch?v=meQd3BAqc2w" target="_blank">
                <div v-motion-pop-visible class="flex flex-col justify-ced items-center bg-[#13131B] p-4 w-[300px] gap-4">
                    <img src="../assets/thumbnails/thumbnail2.webp" class="rounded-xl w-[280px]" alt="thumbnail1">
                    <p class="text-white text-sm text-left w-[260px] truncate">Double down on 18's....</p>
                    <p class="text-[#AAC2E6] uppercase text-xs">Click to view</p>
                </div>
            </a>
            <!-- <div v-motion-pop-visible class="flex flex-col justify-center items-center bg-[#13131B] p-4 w-[300px] gap-4">
                <img src="../assets/thumbnails/thumbnail1.png" alt="thumbnail1">
                <p class="text-white text-sm">WTF… BACK TO BACK KARAMBIT SLAUGHER UNBOXING IN HUGE ALL-IN! (CSGOROLL)</p>
                <p class="text-[#AAC2E6] uppercase text-left text-xs">30 Minutes ago</p>
            </div>
            <div v-motion-pop-visible class="flex flex-col justify-center items-center bg-[#13131B] p-4 w-[300px] gap-4">
                <img src="../assets/thumbnails/thumbnail1.png" alt="thumbnail1">
                <p class="text-white text-sm">WTF… BACK TO BACK KARAMBIT SLAUGHER UNBOXING IN HUGE ALL-IN! (CSGOROLL)</p>
                <p class="text-[#AAC2E6] uppercase text-left text-xs">30 Minutes ago</p>
            </div>
            <div v-motion-pop-visible class="flex flex-col justify-center items-center bg-[#13131B] p-4 w-[300px] gap-4">
                <img src="../assets/thumbnails/thumbnail1.png" alt="thumbnail1">
                <p class="text-white text-sm">WTF… BACK TO BACK KARAMBIT SLAUGHER UNBOXING IN HUGE ALL-IN! (CSGOROLL)</p>
                <p class="text-[#AAC2E6] uppercase text-left text-xs">30 Minutes ago</p>
            </div>
            <div v-motion-pop-visible class="flex flex-col justify-center items-center bg-[#13131B] p-4 w-[300px] gap-4">
                <img src="../assets/thumbnails/thumbnail1.png" alt="thumbnail1">
                <p class="text-white text-sm">WTF… BACK TO BACK KARAMBIT SLAUGHER UNBOXING IN HUGE ALL-IN! (CSGOROLL)</p>
                <p class="text-[#AAC2E6] uppercase text-left text-xs">30 Minutes ago</p>
            </div>
            <div v-motion-pop-visible class="flex flex-col justify-center items-center bg-[#13131B] p-4 w-[300px] gap-4">
                <img src="../assets/thumbnails/thumbnail1.png" alt="thumbnail1">
                <p class="text-white text-sm">WTF… BACK TO BACK KARAMBIT SLAUGHER UNBOXING IN HUGE ALL-IN! (CSGOROLL)</p>
                <p class="text-[#AAC2E6] uppercase text-left text-xs">30 Minutes ago</p>
            </div>
            <div v-motion-pop-visible class="flex flex-col justify-center items-center bg-[#13131B] p-4 w-[300px] gap-4">
                <img src="../assets/thumbnails/thumbnail1.png" alt="thumbnail1">
                <p class="text-white text-sm">WTF… BACK TO BACK KARAMBIT SLAUGHER UNBOXING IN HUGE ALL-IN! (CSGOROLL)</p>
                <p class="text-[#AAC2E6] uppercase text-left text-xs">30 Minutes ago</p>
            </div> -->
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>