<template>
    <section class="flex flex-col justify-center items-center py-12 md:py-16 lg:pt-20 lg:pb-0" id="bonus-offer">
        <div class="py-8 px-4">
            <img v-motion-pop-visible src="../assets/bonus-offer.png" alt="bonus-offer">
        </div>
        <div class="flex flex-wrap justify-center items-stretch gap-6">
            <div v-motion-slide-visible-bottom class="bg-gradient-to-b from-[#B72697] via-[#94B900] to-[#002FA9] p-1 rounded-3xl mx-3">          
                <div class="flex flex-col justify-center items-center rounded-3xl bg-[#1B2B38] border border-[#20202E] py-4 px-8 md:py-8 md:px-24 md:mx-0">
                    <img src="../assets/stake.png" class="w-[200px]" alt="stake">
                    <div class="flex items-center py-3">
                        <img src="../assets/arrow-right.png" class="hidden md:block" alt="arrow-right">
                        <p class="text-sm text-white font-bold uppercase">&nbsp; Stake &nbsp;</p>
                        <img src="../assets/arrow-left.png" class="hidden md:block" alt="arrow-right">
                    </div>
                    <div class="flex flex-col gap-4">
                        <div class="flex flex-col justify-center items-center bg-[#0D0D12] rounded px-16 py-4">
                            <p class="uppercase text-xs text-[#7E7E8F]">Extra</p>
                            <p class="uppercase text-xs text-white font-semibold text-center">10% for first deposit <br> up  to $100 deposited <br> with wager</p>
                        </div>
                        <a href="https://stake.com/?c=oldmetro" target="_blank">
                            <div class="transition ease-in-out delay-100 hover:scale-105 duration-200 uppercase text-sm font-black text-white px-16 py-4 bg-gradient-to-t from-[#08467E] to-[#368EF4] rounded text-center">
                                Claim Bonus
                            </div>
                        </a>
                        <div class="flex justify-center">
                            <img src="../assets/how-to-claim.png" class="w-36" alt="how-to-claim">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-motion-slide-visible-bottom class="flex flex-col justify-center items-center bg-[#13131B] border border-[#20202E] py-6 px-10 mx-4 md:mx-0">
                <img src="../assets/csgo-roll.png" alt="csgo-roll">
                <div class="flex items-center py-3">
                    <img src="../assets/arrow-right.png" class="hidden md:block" alt="arrow-right">
                    <p class="text-sm text-white font-bold uppercase">&nbsp; CSGO ROLL &nbsp;</p>
                    <img src="../assets/arrow-left.png" class="hidden md:block" alt="arrow-right">
                </div>
                <div class="flex flex-col gap-4">
                    <div class="flex flex-col justify-center items-center bg-[#0D0D12] border-2 border-[#811010] rounded px-16 py-4">
                        <p class="uppercase text-xs text-[#7E7E8F]">Main Reward</p>
                        <p class="uppercase text-sm text-white font-extrabold text-center">3 free boxes</p>
                    </div>
                    <div class="flex flex-col justify-center items-center bg-[#0D0D12] rounded px-16 py-4">
                        <p class="uppercase text-xs text-[#7E7E8F]">Extra</p>
                        <p class="uppercase text-sm text-white font-semibold text-center">%5 on all deposits made</p>
                    </div>
                    <button class="transition ease-in-out delay-100 hover:scale-105 duration-200 uppercase text-sm font-black text-white px-16 py-4 bg-gradient-to-t from-[#811010] to-[#FD4747] rounded">
                        Claim Bonus
                    </button>
                    <div class="flex justify-center">
                        <img src="../assets/how-to-claim.png" class="w-36" alt="how-to-claim">
                    </div>
                </div>
            </div>
            <div v-motion-slide-visible-bottom class="flex flex-col justify-center items-center bg-[#13131B] border border-[#20202E] py-6 px-10 mx-4 md:mx-0">
                <img src="../assets/packdraw.png" alt="packdraw">
                <div class="flex items-center py-4">
                    <img src="../assets/arrow-right.png" class="hidden md:block" alt="arrow-right">
                    <p class="text-sm text-white font-bold uppercase">&nbsp; Packdraw &nbsp;</p>
                    <img src="../assets/arrow-left.png" class="hidden md:block" alt="arrow-right">
                </div>
                <div class="flex flex-col gap-4">
                    <div class="flex flex-col justify-center items-center bg-[#0D0D12] border-2 border-[#08467E] rounded px-16 py-4">
                        <p class="uppercase text-xs text-[#7E7E8F]">Main Reward</p>
                        <p class="uppercase text-sm text-white font-extrabold text-center">3 free boxes</p>
                    </div>
                    <div class="flex flex-col justify-center items-center bg-[#0D0D12] rounded px-16 py-4">
                        <p class="uppercase text-xs text-[#7E7E8F]">Extra</p>
                        <p class="uppercase text-sm text-white font-semibold text-center">%5 on all deposits made</p>
                    </div>
                    <button class="transition ease-in-out delay-100 hover:scale-105 duration-200 uppercase text-sm font-black text-white px-16 py-4 bg-gradient-to-t from-[#08467E] to-[#368EF4] rounded">
                        Claim Bonus
                    </button>
                    <div class="flex justify-center">
                        <img src="../assets/how-to-claim.png" class="w-36" alt="how-to-claim">
                    </div>
                </div>
            </div>
            <div v-motion-slide-visible-bottom class="flex flex-col justify-center items-center bg-[#13131B] border border-[#20202E] py-6 px-10 mx-4 md:mx-0">
                <img src="../assets/roobet.png" alt="roobet">
                <div class="flex items-center py-4">
                    <img src="../assets/arrow-right.png" class="hidden md:block" alt="arrow-right">
                    <p class="text-sm text-white font-bold uppercase">&nbsp; roobet &nbsp;</p>
                    <img src="../assets/arrow-left.png" class="hidden md:block" alt="arrow-right">
                </div>
                <div class="flex flex-col gap-4">
                    <div class="flex flex-col justify-center items-center bg-[#0D0D12] border-2 border-[#A77A07] rounded px-16 py-4">
                        <p class="uppercase text-xs text-[#7E7E8F]">Main Reward</p>
                        <p class="uppercase text-sm text-white font-extrabold text-center">3 free boxes</p>
                    </div>
                    <div class="flex flex-col justify-center items-center bg-[#0D0D12] rounded px-16 py-4">
                        <p class="uppercase text-xs text-[#7E7E8F]">Extra</p>
                        <p class="uppercase text-sm text-white font-semibold text-center">%5 on all deposits made</p>
                    </div>
                    <button class="transition ease-in-out delay-100 hover:scale-105 duration-200 uppercase text-sm font-black text-white px-16 py-4 bg-gradient-to-t from-[#A77A07] to-[#EEAF0E] rounded">
                        Claim Bonus
                    </button>
                    <div class="flex justify-center">
                        <img src="../assets/how-to-claim.png" class="w-36" alt="how-to-claim">
                    </div>
                </div>
            </div> -->
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>
