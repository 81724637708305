<template>
    <section class="h-auto challenges-bg">
        <div class="flex flex-col justify-center items-center px-6 md:px-12 pt-32 md:pt-44 lg:pt-44 2xl:pt-52">
            <img v-motion-pop-visible src="../assets/leaderboard-header.png" class="lg:w-[900px] 2xl:w-[1100px]" alt="socials">
            <!-- <div v-motion-pop-visible class="pt-14 text-white text-xl font-black text-center">
                Under Construction. Coming Soon...
            </div> -->
            <div v-motion-pop-visible class="flex justify-center mt-24">
                <img src="../assets/weekly-prize.png" class="w-[90%]" alt="weekly-prize">
            </div>
        </div>
        <LeaderboardSection/>
    </section>
</template>

<script>
import LeaderboardSection from '../sections/LeaderboardSection.vue';
export default {
    components: {
        LeaderboardSection
    },
}
</script>

<style scoped>
.challenges-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
</style>