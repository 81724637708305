<template>
    <section class="h-auto socials-bg">
        <div class="flex justify-center items-center px-6 md:px-12 pt-32 md:pt-44 lg:pt-44 2xl:pt-52">
            <img v-motion-pop-visible src="../assets/socials-header.png" class="lg:w-[600px] 2xl:w-[800px]" alt="socials">
        </div>
        <RecentVideos/>
        <SocialSection/>
    </section>
</template>

<script>
import RecentVideos from '../sections/RecentVideos.vue';
import SocialSection from '../sections/SocialSection.vue';

export default {
    components: {
        SocialSection,
        RecentVideos
    },
}
</script>

<style scoped>
.socials-bg {
  background-image: url('../assets/socials-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>